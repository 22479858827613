import { defineStore } from 'pinia';
import { reactive, ref, type Ref, type UnwrapRef, watch } from 'vue';
import dayjs from 'dayjs';
import { useEventBus } from '@vueuse/core';
import type { AsyncData } from 'nuxt/app';
import {
  MODAL_DELETE,
  MODAL_SEND_REMINDERS,
  MODAL_TYPES,
  MODAL_VOID,
} from '../constants';
import utilitiesApi from '../services/utilitiesApi';
import type {
  FormId,
  UtilitiesForm,
  UtilitiesQuery,
  UtilitiesStats,
} from '../types';
import { useRouter, useZiggy, unref, navigateTo } from '#imports';
import type { FormUploadPayload } from '@utilities/types/requests';
import { utilitiesKey } from '@utilities/services/events';
import { useHelpers } from '~/composables/useHelpers';

const dateFormat = 'DD/MM/YY';
const updatedFromSalesforce: Ref<UnwrapRef<boolean>> = ref(false);

const setAddressString = (data: UtilitiesForm) => {
  if (typeof data === 'undefined' || typeof data.address === 'undefined') {
    return data;
  }

  data.addressString = '';

  if (data.address.line1) {
    data.addressString = data.address.line1.trim() + ', ';
  }

  if (data.address.city) {
    data.addressString += data.address.city.trim() + ', ';
  }

  if (data.address.postcode) {
    data.addressString += `${data.address.postcode}`;
  }

  return data;
};

const setDateStrings = (data: UtilitiesForm) => {
  data.createdAtString = data.createdAt
    ? dayjs(data.createdAt).format(dateFormat)
    : '';
  data.endDateString = data.endDate
    ? dayjs(data.endDate).format(dateFormat)
    : '';
  data.startDateString = data.startDate
    ? dayjs(data.startDate).format(dateFormat)
    : '';
  return data;
};

const mapFormData = (form: UtilitiesForm) => {
  form = setDateStrings(form);

  if (form.address && form.address.street) {
    const streetComponents = form.address.street.split(' ');
    form.address.houseNo = streetComponents.shift();
    form.address.street = streetComponents.join(' ');
  }

  form = setAddressString(form);

  if (form.source?.value === 'utility_account') {
    form.online = true;
  }

  if (Array.isArray(form.tenants)) {
    form.tenantsSigned = form.tenants
      .map((tenant) => tenant.signed)
      .filter(Boolean).length;
  }

  form.tenantCount = form.tenants.length;
  form.signaturesRemaining = form.tenantCount - form.tenantsSigned;

  return form;
};

export const useUtilityStore = defineStore('utilities', () => {
  const { updateRouteQueryParams } = useHelpers();
  const { user } = useAuth();
  const bus = useEventBus(utilitiesKey);
  const router = useRouter();
  const isInitialLoad = ref(true);

  const defaultQuery = {
    filter: {
      from: '',
      search: '',
      status: '',
      to: '',
    },
    page: 1,
    perPage: 15,
    sort: '-created_at',
  };

  const defaultNeedsAttentionQuery = {
    filter: {
      from: '',
      search: '',
      status: '',
      to: '',
    },
    page: 1,
    perPage: 5,
    sort: '-created_at',
  };

  const defaultStats = {
    draft: 0,
    sent: 0,
    pending: 0,
    processing: 0,
    approved: 0,
    active: 0,
    cancelled: 0,
    declined: 0,
    total: 0,
  };

  const stats = reactive<UtilitiesStats>({ ...defaultStats });
  const query = reactive<UtilitiesQuery>({ ...defaultQuery });
  const needsAttentionQuery = reactive<UtilitiesQuery>({
    ...defaultNeedsAttentionQuery,
  });

  const loading = reactive({
    stats: false,
    table: false,
  });

  const needsAttentionLoading = reactive({
    stats: false,
    table: false,
  });

  const forms = ref<UtilitiesForm[]>([]);
  const needsAttentionForms = ref<UtilitiesForm[]>([]);

  const meta = reactive({
    currentPage: 0,
    totalPages: 0,
    lettingSeasons: [],
    total: 0,
  });

  const needsAttentionMeta = reactive({
    currentPage: 0,
    totalPages: 0,
    lettingSeasons: [],
    total: 0,
  });

  const modal = ref<{ open: string; data?: object | null }>({
    open: '',
    data: {},
  });

  // Getters
  const queryParams = computed(() => ({
    'filter[from]': query.filter.from,
    'filter[search]': query.filter.search,
    'filter[status]': query.filter.status,
    'filter[to]': query.filter.to,
    page: query.page,
    perPage: query.perPage,
    sort: query.sort,
  }));

  const needsAttentionQueryParams = computed(() => {
    const now = dayjs();
    const thisOctober = dayjs().set('month', 9).startOf('month');
    const lastOctober = thisOctober.subtract(1, 'year');
    const diffThisOctober = now.diff(thisOctober, 'days');

    if (diffThisOctober < 0) {
      needsAttentionQuery.filter.from = `${lastOctober.format('YYYY-MM-DD')}`;
      needsAttentionQuery.filter.to = `${lastOctober
        .add(1, 'year')
        .year()}-09-30`;
    } else {
      needsAttentionQuery.filter.from = `${thisOctober.format('YYYY-MM-DD')}`;
      needsAttentionQuery.filter.to = `${thisOctober
        .add(1, 'year')
        .year()}-09-30`;
    }

    return {
      'filter[from]': needsAttentionQuery.filter.from,
      'filter[search]': needsAttentionQuery.filter.search,
      'filter[status]': needsAttentionQuery.filter.status,
      'filter[to]': needsAttentionQuery.filter.to,
      attention: true,
      page: needsAttentionQuery.page,
      perPage: needsAttentionQuery.perPage,
      sort: needsAttentionQuery.sort,
    };
  });

  const lettingSeason = computed(
    () => `${query.filter.from}|${query.filter.to}`,
  );

  const needsAttentionRecords = computed(() => {
    if (needsAttentionForms.value.length === 0) {
      return [];
    }

    return needsAttentionForms.value.filter(
      (form) => form.signaturesRemaining !== 0,
    );
  });

  // Actions
  // eslint-disable-next-line require-await
  async function setDefaultLettingSeason() {
    const now = dayjs();
    const thisOctober = dayjs().set('month', 9).startOf('month');
    const lastOctober = thisOctober.subtract(1, 'year');
    const diffThisOctober = now.diff(thisOctober, 'days');

    if (diffThisOctober < 0) {
      query.filter.from = `${lastOctober.format('YYYY-MM-DD')}`;
      query.filter.to = `${lastOctober.add(1, 'year').year()}-09-30`;
    } else {
      query.filter.from = `${thisOctober.format('YYYY-MM-DD')}`;
      query.filter.to = `${thisOctober.add(1, 'year').year()}-09-30`;
    }
  }

  function resetFilters() {
    Object.assign(query, { ...defaultQuery });
  }

  function sortBy(field: string): void {
    query.sort = query.sort === field ? `-${field}` : field;
  }

  async function getStats() {
    try {
      loading.stats = true;
      const { data } = await utilitiesApi.accounts.stats({
        from: query.filter.from,
        to: query.filter.to,
      });

      if (data.value?.data) {
        Object.assign(stats, data.value.data);
      }
    } catch (e) {
      console.error(e);
    } finally {
      loading.stats = false;
    }
  }

  async function getAccounts(dontUpdateRouteQueryParams = false) {
    try {
      loading.table = true;

      if (!updatedFromSalesforce.value) {
        updatedFromSalesforce.value = true;
        await utilitiesApi.accounts.create();
      }

      const { data } = await utilitiesApi.accounts.get(unref(queryParams));

      if (data.value) {
        forms.value = data.value.data.map(mapFormData);
        Object.assign(meta, data.value.meta);
      }

      if (!dontUpdateRouteQueryParams) {
        updateRouteQueryParams({
          query: unref(queryParams),
          options: isInitialLoad.value
            ? {}
            : {
                element: '#utility-orders-table',
                offset: 100,
                smooth: true,
              },
        });
      }

      if (isInitialLoad.value) {
        isInitialLoad.value = false;
      }
    } catch (e) {
      console.error(e);
    } finally {
      loading.table = false;
    }
  }

  async function getNeedsAttentionAccounts() {
    try {
      needsAttentionLoading.table = true;

      const { data } = await utilitiesApi.accounts.get(
        unref(needsAttentionQueryParams),
      );

      if (data.value) {
        needsAttentionForms.value = data.value.data.map(mapFormData);
        Object.assign(needsAttentionMeta, data.value.meta);
      }
    } catch (e) {
      console.error(e);
    } finally {
      needsAttentionLoading.table = false;
    }
  }

  async function getAccount(id: FormId) {
    try {
      const { data } = await utilitiesApi.accounts.show(id);
      return data.value;
    } catch (e) {
      console.error(e);
    }
  }

  async function deleteAccount(ids: FormId[]) {
    try {
      await utilitiesApi.accounts.destroy({ forms: ids });
    } catch (e) {
      console.error(e);
    }
  }

  async function uploadForms(payload: { forms: FormUploadPayload[] }) {
    try {
      await utilitiesApi.orders.upload(payload);
    } catch (e) {
      console.error(e);
    }
  }

  function openModal(type: string, data = null) {
    if (!MODAL_TYPES.includes(type)) {
      throw new Error(`Invalid modal type: ${type}`);
    }

    if (modal.value.open === type) {
      return;
    }

    modal.value = { open: type, data };
  }

  function closeModal(checkType = '') {
    if (checkType && !modalIsOpen(checkType)) {
      return;
    }

    modal.value = { open: '', data: {} };
  }

  function modalIsOpen(type: string) {
    return modal.value.open === type;
  }

  async function refresh(dontUpdateRouteQueryParams = false) {
    await Promise.all([
      getStats(),
      getAccounts(dontUpdateRouteQueryParams),
      getNeedsAttentionAccounts(),
    ]);
  }

  async function clone(form: any) {
    const { data } = await utilitiesApi.orders.clone(form.id);
    if (data.value?.id) {
      router.push(`/utilities/order/${data.value.id}/details`);
    }
  }

  async function preview(form: any) {
    const { data } = await utilitiesApi.orders.previewLink(form.id);
    if (data.value?.preview_url) {
      await navigateTo(data.value.preview_url, {
        external: true,
        open: { target: '_blank' },
      });
    }
  }

  function download(form: any) {
    navigateTo(useZiggy('agents.utilities.orders.download', form.id), {
      external: true,
    });
  }

  function getGroupSize(form) {
    return form.tenantCount > 0
      ? `${form.tenantCount} (${form.tenantsSigned}/${form.tenantCount})`
      : '-';
  }

  function deleteUtilityForm(formId: number): AsyncData<never, any> {
    return utilitiesApi.orders.delete(formId);
  }

  // Events
  bus.on(async (event, payload) => {
    switch (event) {
      case 'void-order':
        openModal(MODAL_VOID, payload);
        break;
      case 'refresh-table':
        await refresh();
        break;
      case 'clone-order':
        await clone(payload);
        break;
      case 'preview-order':
        await preview(payload);
        break;
      case 'download-order':
        download(payload);
        break;
      case 'send-reminders':
        openModal(MODAL_SEND_REMINDERS, payload);
        break;
      case 'delete-order':
        openModal(MODAL_DELETE, payload);
        break;
    }
  });

  // Watchers
  watch(
    () => query,
    () => {
      getAccounts();
    },
    { deep: true, immediate: false },
  );

  watch(
    () => needsAttentionQuery,
    () => {
      getNeedsAttentionAccounts();
    },
    { deep: true, immediate: false },
  );

  watch([() => query.filter.from, () => query.filter.to], getStats);

  return {
    // State
    forms,
    needsAttentionForms,
    needsAttentionRecords,
    lettingSeason,
    loading,
    needsAttentionLoading,
    meta,
    needsAttentionMeta,
    modal,
    query,
    needsAttentionQuery,
    queryParams,
    needsAttentionQueryParams,
    stats,
    user,
    // Actions
    closeModal,
    deleteAccount,
    getAccount,
    getAccounts,
    getNeedsAttentionAccounts,
    getStats,
    modalIsOpen,
    openModal,
    refresh,
    resetFilters,
    uploadForms,
    setDefaultLettingSeason,
    sortBy,
    getGroupSize,
    deleteUtilityForm,
  };
});
